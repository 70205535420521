/**
 * Définit un cookie avec le nom, la valeur et la durée d'expiration spécifiés.
 * @param cname - Le nom du cookie.
 * @param cvalue - La valeur du cookie.
 * @param exdays - Le nombre de jours avant l'expiration du cookie.
 */

export const setCookie = function (cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};
/**
 * Vérifie si un cookie avec le nom spécifié existe.
 * @param cname - Le nom du cookie à vérifier.
 * @returns `true` si le cookie existe, sinon `false`.
 */

export const checkCookie = function (cname) {
    var cookie = this.getCookie(cname);
    if (cookie != "") {
        return true;
    } else {
        return false;
    }
};
/**
 * Récupère la valeur d'un cookie à partir de son nom.
 * @param cname - Le nom du cookie.
 * @returns La valeur du cookie ou une chaîne vide si le cookie n'est pas trouvé.
 */

export const getCookie = function (cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};
