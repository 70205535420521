/**
 * Obtient la position du toucher (start, move, end) et les informations associées.
 * @param touchStartEvent - L'événement "touchstart" initial.
 * @param callbackTouchStart - Fonction de rappel lors du toucher initial (touchstart).
 * @param callbackTouchMove - Fonction de rappel lors du déplacement du toucher (touchmove).
 * @param callbackTouchEnd - Fonction de rappel lorsque le toucher se termine (touchend).
 * @returns Un objet contenant les informations sur le toucher.
 */

export const getTouchPosition = function (touchStartEvent, callbackTouchStart, callbackTouchMove, callbackTouchEnd) {
    var obj = {};
    var startTime;
    var endTime;
    $(document).on('touchstart.touchPosition touchend.touchPosition touchmove.touchPosition', function (e) {
        if (!startTime) {
            obj.touchStartX = touchStartEvent.changedTouches[0].clientX;
            obj.touchStartY = touchStartEvent.changedTouches[0].clientY;
            startTime = new Date();
            if (callbackTouchStart) {
                callbackTouchStart(obj);
            }
        } else if (e.type === "touchmove") {
            obj.touchMoveX = e.changedTouches[0].clientX;
            obj.touchMoveY = e.changedTouches[0].clientY;
            obj.distanceMoveX = obj.touchMoveX - obj.touchStartX;
            obj.distanceMoveY = obj.touchStartY - obj.touchMoveY;
            endTime = new Date();
            obj.duration = endTime - startTime;
            if (callbackTouchMove) {
                callbackTouchMove(obj);
            }
        } else if (e.type === "touchend") {
            obj.touchEndX = e.changedTouches[0].clientX;
            obj.touchEndY = e.changedTouches[0].clientY;
            obj.distanceEndX = obj.touchEndX - obj.touchStartX;
            obj.distanceEndY = obj.touchStartY - obj.touchEndY;
            endTime = new Date();
            obj.duration = endTime - startTime;
            if (callbackTouchEnd) {
                callbackTouchEnd(obj);
            }
            $(document).off('.touchPosition');
        }
    });
    return obj;
};
