/**
 * Affiche ou masque une boîte de chargement avec une icône de chargement.
 * @param status - Indique si la boîte de chargement doit être affichée (true) ou masquée (false).
 * @param colorClass - La classe CSS pour la couleur de l'icône de chargement.
 */

export const loading = function (status, colorClass = "text-primary") {
    if (status) {
        $('body').attr('data-loading-box', 'true');
        var loaderHtml = `
        <div class="erpl_loading-box d-flex position-fixed">
            <svg aria-hidden="true" class="spinner m-auto es_icon es_icon-spinner es_icon-64 ${colorClass}">
                <use xlink:href="#es_icon-spinner"></use>
            </svg>
        </div>
        `;
        $(loaderHtml).appendTo('body');
    } else {
        $('body').attr('data-loading-box', 'false');
        if ($('.erpl_loading-box').length) {
            $('.erpl_loading-box').remove();
        }
    }
};
