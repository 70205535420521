/**
 * Génère un code HTML pour une boîte de dialogue modale.
 * @param id - L'identifiant unique de la boîte de dialogue.
 * @param cssClass - Les classes CSS supplémentaires pour personnaliser l'apparence.
 * @param title - Le titre de la boîte de dialogue.
 * @param body - Le contenu principal de la boîte de dialogue.
 * @param showHeader - Indique si l'en-tête de la boîte de dialogue doit être affiché.
 * @param showFooter - Indique si le pied de page de la boîte de dialogue doit être affiché.
 * @param size - La taille de la boîte de dialogue ("sm", "md", "lg", "xl" ou vide pour la taille par défaut).
 * @returns Le code HTML pour la boîte de dialogue modale.
 */

export const doModal = function (id, cssClass, title, body, showHeader, showFooter, size) {
    if (typeof showHeader === "undefined") { showHeader = true; }
    if (typeof showFooter === "undefined") { showFooter = true; }
    if (typeof id === "undefined") { console.error("Aucun ID spécifié"); }
    if (typeof cssClass === "undefined") { cssClass = ""; }
    if (typeof title === "undefined") { title = ""; }
    if (typeof body === "undefined") { body = ""; }
    if (typeof size === "undefined") { size = ""; }
    var html = '<div class="modal fade ' + cssClass + ' " id="' + id + '" tabindex="-1" role="dialog" aria-labelledby="' + id + 'ModalTitle" aria-hidden="true">';
    html += '<div class="modal-dialog modal-dialog-centered ' + size + '" role="document"> ';
    html += '<div class="modal-content">';
    // Header
    if (showHeader) {
        html += '<div class="modal-header d-none d-lg-flex">';
        html += '<h5 class="modal-title" id="membersresearchserviceModalTitle">' + title + '</h5 > ';
        html += '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>';
        html += '</div>';
    }
    // Body
    html += '<div class="modal-body">' + body + '</div>';
    //Footer
    if (showFooter) {
        html += '<div class="modal-footer d-none d-lg-flex"><button type="button" class="btn btn-default" data-dismiss="modal">Close</button></div>';
    }
    html += '</div>'; // Close modal-content
    html += '</div>'; // Close modal-dialog
    html += '</div>'; // Close modal
    return html;
};
