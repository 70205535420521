/**
 * Les points de rupture pour les différentes tailles d'écran.
 */

export const breakpoints = {
    xs: 0,
    sm: 480,
    md: 640,
    lg: 750,
    xl: 1020,
    xxl: 1200
};
/**
 * Renvoie le point de rupture actuel en fonction de la largeur de la fenêtre.
 * @returns Le nom du point de rupture actuel ("xs", "sm", "md", "lg", "xl", "xxl") ou `null` si aucun point de rupture ne correspond.
 */

export const currentBreakpoint = function () {
    // Identification du breakpoint actuel
    var windowWidth = $(window).outerWidth();
    if (windowWidth < evostrap.breakpoints.sm) {
        return "xs";
    } else if ((windowWidth >= evostrap.breakpoints.sm) && (windowWidth < evostrap.breakpoints.md)) {
        return "sm";
    } else if ((windowWidth >= evostrap.breakpoints.md) && (windowWidth < evostrap.breakpoints.lg)) {
        return "md";
    } else if ((windowWidth >= evostrap.breakpoints.lg) && (windowWidth < evostrap.breakpoints.xl)) {
        return "lg";
    } else if ((windowWidth >= evostrap.breakpoints.xl) && (windowWidth < evostrap.breakpoints.xxl)) {
        return "xl";
    } else if (windowWidth >= evostrap.breakpoints.xxl) {
        return "xxl";
    } else {
        return null;
    }
};
