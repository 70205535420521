// String Proto - Capitalize
String.prototype.capitalize = function () { return this.charAt(0).toUpperCase() + this.slice(1) };
// Plugin jQuery - ToggleAttr -  http://andreasnylin.com
(function ($) { $.fn.toggleAttr = function (attribute, attributeValues) { function getNextValue(value) { var i, l, nextValue; for (i = 0, l = attributeValues.length; i < l; i++) { if (attributeValues[i] === value) { break; } } if (i + 1 >= attributeValues.length) { nextValue = attributeValues[0]; } else { nextValue = attributeValues[i + 1]; } return nextValue; } return this.each(function () { var $this = $(this), hasValues = typeof attributeValues != 'undefined', arrayValues = hasValues && attributeValues instanceof Array, value = $this.attr(attribute), newValue = null; if (!hasValues && !$this.is('[' + attribute + ']')) { $this.attr(attribute, attribute); } else if (!hasValues) { $this.removeAttr(attribute); } else if (!arrayValues && value === attributeValues) { $this.removeAttr(attribute); } else if (!arrayValues) { $this.attr(attribute, attributeValues); } else if (arrayValues) { $this.attr(attribute, getNextValue(value)); } }); }; })(jQuery);
// Plugin jQuery - Window Resized
$.fn.resized = function (callback, timeout) { $(this).on('resize',function () { var $this = $(this); if ($this.data('resizeTimeout')) { clearTimeout($this.data('resizeTimeout')); } $this.data('resizeTimeout', setTimeout(callback, timeout)); }); };
// Plugin jQuery - Item dans le viewport
$.fn.inViewport = function () { var rect = $(this)[0].getBoundingClientRect(); var elemTop = rect.top; var elemBottom = rect.bottom; var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight); return isVisible; }
// JS Array Filter
if (!Array.prototype.filter) { Array.prototype.filter = function (func, thisArg) { 'use strict'; if (!((typeof func === 'Function' || typeof func === 'function') && this)) throw new TypeError(); var len = this.length >>> 0, res = new Array(len), t = this, c = 0, i = -1; if (thisArg === undefined) { while (++i !== len) { if (i in this) { if (func(t[i], i, t)) { res[c++] = t[i]; } } } } else { while (++i !== len) { if (i in this) { if (func.call(thisArg, t[i], i, t)) { res[c++] = t[i]; } } } } res.length = c; return res; }; }
// Array.prototype.find polyfill
if (!Array.prototype.find) { Object.defineProperty(Array.prototype, 'find', { value: function (predicate) { if (this == null) { throw new TypeError('"this" is null or not defined'); } var o = Object(this); var len = o.length >>> 0; if (typeof predicate !== 'function') { throw new TypeError('predicate must be a function'); } var thisArg = arguments[1]; var k = 0; while (k < len) { var kValue = o[k]; if (predicate.call(thisArg, kValue, k, o)) { return kValue; } k++; } return undefined; }, configurable: true, writable: true }); }
// append
// Source: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/append()/append().md
(function (arr) {
    arr.forEach(function (item) {
      if (item.hasOwnProperty('append')) {
        return;
      }
      Object.defineProperty(item, 'append', {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function append() {
          var argArr = Array.prototype.slice.call(arguments),
            docFrag = document.createDocumentFragment();
  
          argArr.forEach(function (argItem) {
            var isNode = argItem instanceof Node;
            docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
          });
  
          this.appendChild(docFrag);
        }
      });
    });
  })([Element.prototype, Document.prototype, DocumentFragment.prototype]);

  // Source: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/prepend()/prepend().md
(function (arr) {
    arr.forEach(function (item) {
      if (item.hasOwnProperty('prepend')) {
        return;
      }
      Object.defineProperty(item, 'prepend', {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function prepend() {
          var argArr = Array.prototype.slice.call(arguments),
            docFrag = document.createDocumentFragment();
          
          argArr.forEach(function (argItem) {
            var isNode = argItem instanceof Node;
            docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
          });
          
          this.insertBefore(docFrag, this.firstChild);
        }
      });
    });
  })([Element.prototype, Document.prototype, DocumentFragment.prototype]);