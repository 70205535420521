/** EVOSTRAP */
__webpack_public_path__ = document.getElementById('evostrap') ? document.getElementById('evostrap').getAttribute('src').split('js/evostrap.js')[0] : '/evostrap/dist/';

import * as Utils from "./modules/utils";

// Import base Bootstrap JS
import '../../evostrap-core/src/js/index';

// POLYFILL - PROTOTYPE - PLUGIN 
import './modules/polyfill';

const load = {};

const notLoaded = `Les conditions de chargement du script ne sont pas valide. Pour forcer le chargement, passer le paramètre forceLoading à true`

/** ERPL toInputList */
load.toInputList = function() {
	return new Promise((resolve, reject) => {
		if ($('.erpl_to-input-list').length) {
			import( /* webpackChunkName: "js/erpl-to-input-list" */ './modules/erpl-to-input-list').then(val => {
				resolve(val)
			}).catch((reason) => {
				reject(reason)
			});
		} else {
			resolve(notLoaded)
		}

	})
}

// ScrollTo - JQuery
/* Faire défiler la page jusqu'à l'élément que vous spécifiez. */
jQuery.fn.scrollTo = function(elem, speed, offsetTop) {
	if (!offsetTop) {
		offsetTop = 0;
	}
	$(this).animate({
		scrollTop: $(this).scrollTop() - $(this).offset().top + $(elem).offset().top + offsetTop
	}, speed == undefined ? 1000 : speed);
	return this;
};

/** COOKIE POLICY */
load.erplPolicyCookie = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('.erpl_policy-cookie').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-policy-cookie" */ './modules/erpl-policy-cookie').then(val => {
				resolve(val)
			}).catch((reason) => {
				reject(reason)
			});
		} else {
			resolve(notLoaded)
		}
	})
}

/** Divers */

//──── AIDE A L OPTIMISATION DES STYLES SANS JS ──────────────────────────────────────────

load.jsHelper = function() {
	$('body').attr('data-js', 'true');
}

//──── Popovers et Tooltip everywhere ────────────────────────────────────────────────────

load.initTooltips = function() {
	$(function() {
		$('[data-toggle="popover"]').popover()
	})
	$(function() {
		$('[data-toggle="tooltip"]').tooltip()
	})

	$(function() {
		$('[data-toggle="popover-html"]').popover({
			html: true
		})
	})
}

//──── Clearable ─────────────────────────────────────────────────────────────────────────

load.initClearableInput = function() {
	$('[data-clearable="true"]').on('keyup', function() {
		var $input = $(this);

		if ($input.val().length) {
			$input.addClass('clearable');
			$input.next('.input-group-append').find('.btn-clearable').one('click', function() {
				$input.val('').trigger('keyup');
				$input[0].dispatchEvent(new CustomEvent('change'))
				$input.removeClass('clearable');
			})
		} else {
			$input.removeClass('clearable');
		}

	});
}

//──── ERPL MOVE ─────────────────────────────────────────────────────────────────────────

load.erplMove = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('.erpl_move').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-move" */ './modules/erpl-move').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			});
		} else {
			resolve(notLoaded)
		}

	})
}

//──── SMOOTH SCROLL ─────────────────────────────────────────────────────────────────────

load.erplSmoothScroll = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('.erpl_smooth-scroll').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-header" */ './modules/erpl-smooth-scroll').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			});

		} else {
			resolve(notLoaded)
		}

	})
}

/** Footer */
load.erplFooter = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('footer').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-footer" */ './modules/erpl-footer').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			});
		} else {
			resolve(notLoaded)
		}

	})
}

/** ERPL EUROMAP  */
load.erplEuroMap = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('.erpl_map-europe').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-map-europe" */ './modules/erpl-map-europe').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			});
		} else {
			resolve(notLoaded)
		}

	})
}

/** ERPL Animate */
load.erplAnimate = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('.erpl_animate').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-animate" */ './modules/erpl-animate').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			});

		} else {
			resolve(notLoaded)
		}

	})
}

/** ERPL Tree Select   */
load.erplTree = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('.erpl_tree').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-tree" */ './modules/erpl-tree').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			});

		} else {
			resolve(notLoaded)
		}

	})
}

/** ERPL DROPDOWN  */
load.erplDropdown = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('.erpl_dropdown').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-dropdown" */ './modules/erpl-dropdown').then(module => {
				resolve($.fn.erplDropdown)

				if (!forceLoading) {
					$('.erpl_dropdown').each(function() {
						$(this).erplDropdown();
					})
				}
			}).catch(reason => {
				reject(reason)
			});
		} else {
			resolve(notLoaded)
		}

	})
}

/** ERPL CHECKBOX LIST */
load.erplCheckboxList = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('.erpl_checkbox-list').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-checkbox-list" */ './modules/erpl-checkbox-list').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			});

		} else {
			resolve(notLoaded)
		}

	})
}

/** FORM VALIDATION */
load.erplFormValidation = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('.erpl_form-validation').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-form-validation" */ './modules/erpl-form-validation').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			});
		} else {
			resolve(notLoaded)
		}

	})
}

/** ERPL FORM STEPS */
load.erplFormSteps = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('.erpl_form-steps').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-form-steps" */ './modules/erpl-form-steps').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			});
		} else {
			resolve(notLoaded)
		}

	})
}

/** Erpl_full-width-banner-section parallax */
load.erplFullWidthBannerSection = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('.erpl_full-width-banner-section').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-full-width-banner-section" */ './modules/erpl-full-width-banner-section').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			});
		} else {
			resolve(notLoaded)
		}

	})
}

/** ERPL FORM AUTOSUBMIT */
load.initAutoSubmit = function() {
	$('form[data-autosubmit="true"]').on('change', function() {
		$(this).trigger('submit');
	})
}

/** ERPL TABLE RESPONSIVE */
load.erplTableResponsive = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('.table.erpl_table-responsive[data-responsive-breakpoint]').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-table-responsive" */ './modules/erpl-table-responsive').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			});

		} else {
			resolve(notLoaded)
		}

	})
}

/** ERPL SLIDESHOW */
load.erplSlideshow = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('.erpl_slideshow').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-slideshow" */ './modules/erpl-slideshow').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			})
		} else {
			resolve(notLoaded)
		}

	})
}

/** Scrollbar personnalisée */
load.erplScrollbar = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('.erpl_scrollbar').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-scrollbar" */ './modules/erpl-scrollbar').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			})
		} else {
			resolve(notLoaded)
		}

	})
}

/** ERPL TAGS INPUT */
load.erplTagsInput = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('.erpl_tags-input').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-tags-input" */ './modules/erpl-tags-input').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			})
		} else {
			resolve(notLoaded)
		}
	})
}

/** ERPL CAPTCHA */
load.erplCaptcha = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('.erpl_captcha').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-captcha" */ './modules/erpl-captcha').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			})
		} else {
			resolve(notLoaded)
		}

	})
}

/** HEMICYCLE */
load.erplHemicycle = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('.erpl_hemicycle').length || forceLoading) {
			import( /* webpackChunkName: "js/widgets/hemicycle/hemicycle" */ './../../widgets/hemicycle/js/hemicycle').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			})
		} else {
			resolve(notLoaded)
		}
	})
}

/** SPLIT SCREEN */
load.erplSplitScreen = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('.erpl_split-screen').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-split-screen" */ './modules/erpl-split-screen').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			})
		} else {
			resolve(notLoaded)
		}
	})
}

/** FORM STATUS */
load.erplFormStatus = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('.erpl_form-status').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-form-status" */ './modules/erpl-form-status').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			})
		} else {
			resolve(notLoaded)
		}
	})
}

/** POSITION STICKY RELATED */
load.erplPositionStickyRelated = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('[data-position-sticky-related]').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-position-sticky-related" */ './modules/erpl-position-sticky-related').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			})
		} else {
			resolve(notLoaded)
		}
	})
}

/** DRAG AND DROP */
load.erplDragAndDrop = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if (forceLoading) {
			import( /* webpackChunkName: "js/erpl-drag-and-drop" */ './modules/erpl-drag-and-drop').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			})
		} else {
			resolve(notLoaded)
		}
	})
}

/** NOTIFICATION */
load.erplToast = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if (forceLoading) {
			import( /* webpackChunkName: "js/erpl-toast" */ './modules/erpl-toast').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			})
		} else {
			resolve(notLoaded)
		}
	})
}

/** ACCORDION */
load.erplAsync = function(forceLoading) {
	return new Promise((resolve, reject) => {
		if ($('[data-async-src]').length || forceLoading) {
			import( /* webpackChunkName: "js/erpl-async-data" */ './modules/erpl-async-data').then(val => {
				resolve(val)
			}).catch(reason => {
				reject(reason)
			})
		} else {
			resolve(notLoaded)
		}
	})
}

/** erpl Glossary */
load.erplGlossary = function(forceLoading) {
	return new Promise((resolve, reject) => {
		import( /* webpackChunkName: "js/erpl-glossary" */ './modules/erpl-glossary').then(Module => {
			let ErplGlossary = Module.ErplGlossary;
			resolve(ErplGlossary)

			if (!forceLoading) {
				let hashedLinks = document.querySelectorAll('.erpl_glossary a[href^="#"]');
				if (hashedLinks.length) {
					document.querySelectorAll('.erpl_glossary').forEach(glossaryElement => {
						new ErplGlossary(glossaryElement)
					})
				}
			}

		}).catch(err => reject(err))

	})
}

/** Import TS modules */
const tsModules = require('../ts/dist/modules/load');

// Construction de l'objet evostrap.
const evostrap = {...{
    load: {...load, ...tsModules.evostrap.load },
    icon : tsModules.evostrap.icon,
    ajax : tsModules.evostrap.ajax,
    focusable : tsModules.evostrap.focusable,
	debounce : tsModules.evostrap.debounce,
	delay : tsModules.evostrap.delay

}, ...Utils}

// Exposition global
window.evostrap = evostrap;

// Chargement de l'ensemble des scripts
$(function() {
	$(Object.keys(evostrap.load)).each(function() {
		evostrap.load[this]()?.catch((err) => {
			console.warn(err);
			console.warn(this);
		});
	})
});