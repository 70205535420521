/**
 * Contient les codes des touches de clavier courantes.
 */

export const key = {
    left: 37,
    up: 38,
    right: 39,
    down: 40,
    enter: 13,
    space: 32,
    ctrl: 91,
    shift: 16,
    escape: 27,
    tab: 9
};
